import React from 'react'
import FileSaver from 'file-saver';
import { navigate } from 'gatsby'
import clsx from 'clsx'
import * as Cookies from 'js-cookie';
import GoBack from '../../../../components/pricing/goBack'
import { useDispatch, useSelector } from 'react-redux'
import putPricingState from '../../../../procedures/pricing/putPricingState'
import packPricingObject from '../../../../functions/pricing/packPricingObject'
import ContactUs from '../../../../components/contactUs'
import PRICING_ACTIONS from '../../../../redux/actions/Pricing'
import styles2 from '../../../../components/contactUs/styles.module.css'
import './styles.css'

const PricingSamplesLayout = () => {
  const dispatch = useDispatch()
  const Pricing = useSelector((state) => state.Pricing)

  const setSamples = () => {
    const setAnswer = PRICING_ACTIONS.setSamples(1)
    dispatch(setAnswer)

    let pricingObject = packPricingObject(Pricing)
    pricingObject.samples = 1
    const idCookie = Cookies.getJSON('opz-pricing-id')
    const id = idCookie ? idCookie.id : null
    if (!id) {
      return () => {
      }
    }
    putPricingState(Pricing.id, pricingObject)
    navigate('/cost-to-make-an-app/course1')
  }

  return (
    <>
      <div className="container">
        <center className={clsx('container', styles2.mycontainer2)}>
          <div className="section__p d-flex justify-content-center flex-column">
            <div className="container">
              <h1 className="section__h1">Selected Sample Apps</h1>
              <p className="section__p">
                While there are 100+ apps that OpZoom has been involved in, the list below represents a short selection
                based on different criteria.
              </p>
            </div>
            <div className="container">
              <h1 className="section__h1">Most Popular Apps</h1>
              <div>
                  <p className="section__p">
                    The list <a href="/sample-apps">HERE</a> shows the top 3 most popular apps developed using
                    OpZoom's technology. These include:
                  </p>
                  <ul>
                    <li><b>Reflectly</b>: 1M+ installs</li>
                    <li><b>Hamilton</b>: 0.95M+ installs</li>
                    <li><b>MusicTutor</b>: 0.92M+ installs</li>
                  </ul>
                </div>
            </div>
            <div className="container">
              <h1 className="section__h1">OpZoom branded Apps</h1>
                <div>
                  <p className="section__p">
                  To help companies speed up testing, OpZoom has published the following early stage apps with OpZoom branding.
                  </p>
                  <ul>
                    <li><a href="https://apps.apple.com/ph/app/kinsey-collection/id1499561200">The Kinsey Collection (iPad):</a> An iPad app that contains 210+ pages of amazing multimedia information including videos and audio.</li>
                    <li><a href="https://apps.apple.com/us/app/surgexp/id1564192548">SurgExp (iPhone):</a> This app helps a doctor sruvery his patients to see how they felt in the different stages of their surgery.</li>
                    <li><a href="https://play.google.com/store/apps/details?id=com.opzoom.medsurvey&hl=en_US&gl=US">SurgExp (Android):</a> Shows the same app as above on the Google Play Store.</li>
                  </ul>
              </div>
            </div>
          </div>
        </center>
        <center className={clsx('container', styles2.mycontainer2)}>
          <div className="section__p d-flex justify-content-center flex-column">
            <h1 className="section__h1">Product Marketing Formula</h1>
            <p>
              Would you do trial and error in marketing if you don't have to?
            </p>
            <p>
              There is a formula that has been taught since the dawn of internet marketing era in 2005.
              This path is proven by over 100,000 entrepreneurs who created business valued at
              over 1 Billion dollars. You can have this for <b>FREE</b>.
            </p>
            <p>
              Online product marketing that can reach a huge market is explained most clearly by this
              great teacher with notes, examples, exercises and interview with real entrepreneurs in
              various niches ranging from fishing to knitting.
            </p>
            <p>
              This formula can save you a lot of resources by reducing your effort dramatically.
              It provides a proven path to success with a lot of examples
              of marketing machines that have worked very well.
            </p>
            <p>
              To learn the product marketing formula click below.
            </p>
            <center>
              <button type="button" className="button" onClick={() => {
                setSamples()
              }}>
                Learn the Product Marketing Formula
              </button>
            </center>
          </div>
        </center>

          {(Pricing.verifyCount === 0) && <ContactUs/>}

      </div>

      <GoBack prevQuestionId={'dev-steps'}/>
    </>
  )
}
export default PricingSamplesLayout
