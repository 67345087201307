import React from "react";
import PropTypes from "prop-types";
import Head from "../../components/Head/Head";
import Header from "../../components/Header/Header";
import GoBack from '../../components/pricing/goBack'
import * as Cookies from 'js-cookie';
import PricingSamplesLayout from "../../layouts/pricing/question/samples";
import { useDispatch, useSelector } from 'react-redux'
import PRICING_ACTIONS from '../../redux/actions/Pricing'
import putPricingState from '../../procedures/pricing/putPricingState'
import packPricingObject from '../../functions/pricing/packPricingObject'
import HeaderPlaceholder from "../../components/common/HeaderPlaceholder/HeaderPlaceholder";
import { navigate } from "gatsby";

const SamplePage = ({ location }) => {
  const dispatch = useDispatch()
  const Pricing = useSelector((state) => state.Pricing)

  const setSamples = () => {
    const setAnswer = PRICING_ACTIONS.setSamples(1)
    dispatch(setAnswer)

    let pricingObject = packPricingObject(Pricing)
    pricingObject.samples = 1
    const idCookie = Cookies.getJSON('opz-pricing-id')
    const id = idCookie ? idCookie.id : null
    if (!id) {
      return () => {
      }
    }
    putPricingState(Pricing.id, pricingObject)
    navigate('/cost-to-make-an-app/video')
  }

  return (
    <>
      <Head />
      <Header pageId={location.pathname} />
      <section className="full-section">
        <HeaderPlaceholder />
       <PricingSamplesLayout />
      </section>
    </>
  );
};

SamplePage.propTypes = {
  location: PropTypes.shape({
    key: PropTypes.string,
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
  }).isRequired,
};

export default SamplePage;
